import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';
import {UploadService} from './upload.service';

@Injectable({
    providedIn: 'root'
})
export class FileService extends BaseService {
    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                public fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    fileUpload(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    makeFileRequestMultiple(params, event) {
        return this.fileUploadService.makeFileRequest(event, AppUrl.CHAT_FILE_UPLOAD(), params);
    }

    makeFileRequestSingle(params, event) {
        return this.fileUploadService.makeFileRequest(event, AppUrl.CHAT_FILE_UPLOAD(), params);
    }

    // fileUploadQuestionPaper(unique_id, params, event): Observable<any> {
    //   return this.fileUploadService.fileUpload(AppUrl.QUESTION_PAPER_UPLOAD(unique_id), params, event);
    // }
}
