import {Component, ElementRef, HostListener, OnInit, ViewChild} from "@angular/core";
import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {StorageService} from "../../shared/services/storage.service";
import {GlobalService} from "../../shared/services/global.service";
import {AuthService} from "../../shared/services/auth.service";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import {DataService} from "../../shared/services/data.service";
import {UserService} from "../../shared/services/user.service";
import {AppComponent} from "../../app.component";
import * as THREE from 'three';
import randomQuote from 'random-quotes'; // Import the package
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {
    @ViewChild('threejsContainer', { static: true }) threejsContainer!: ElementRef;
    public loginForm: FormGroup;
    public show: boolean = false
    submittedLogin: any = false;
    isBusy: any = false;
    private echo: Echo;
    window = {Pusher: null};
    user: any;
    userData: any;
    quote: string = ''; // To store the quote
    author: string = ''; // To store the author of the quote
    constructor(private fb: FormBuilder,
                public router: Router,
                private globalService: GlobalService,
                private authService: AuthService,
                private dataService: DataService,
                private userService: UserService,
                private appComponent: AppComponent) {
        this.checkLogin();
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.loginForm = this.fb.group({
            username: ["", Validators.required],
            password: ["", Validators.required],
            login:['WEB']
        });
        this.initThreeJs();
        this.displayRandomQuote();
    }

    displayRandomQuote(): void {
        const quoteObject = randomQuote(); // Get random quote object
        this.quote = quoteObject.body;
        this.author = quoteObject.author;
    }

    initThreeJs(): void {
        const container = document.getElementById('threejs-container')!;

        // Set up the scene, camera, and renderer
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        container.appendChild(renderer.domElement);

        // Create a light color gradient background
        const gradientColors = [new THREE.Color(0x87CEFA), new THREE.Color(0xFFFFFF)]; // Light blue to white
        const backgroundGeometry = new THREE.PlaneGeometry(2, 2);
        const backgroundMaterial = new THREE.MeshBasicMaterial({
            color: gradientColors[0],
            side: THREE.DoubleSide,
            transparent: true,
            opacity: 0,
        });
        const backgroundMesh = new THREE.Mesh(backgroundGeometry, backgroundMaterial);
        backgroundMesh.position.z = -10; // Position it behind the particles
        scene.add(backgroundMesh);

        // Create particle geometry
        const particlesGeometry = new THREE.BufferGeometry();
        const particlesCount = 5000;
        const positions = new Float32Array(particlesCount * 3);
        const colors = new Float32Array(particlesCount * 3); // For particle colors

        // Create a spherical distribution of particles
        for (let i = 0; i < particlesCount * 3; i += 3) {
            const theta = Math.random() * 2 * Math.PI;
            const phi = Math.acos(2 * Math.random() - 1);
            const radius = Math.random() * 5; // Adjust for a larger sphere

            positions[i] = radius * Math.sin(phi) * Math.cos(theta);
            positions[i + 1] = radius * Math.sin(phi) * Math.sin(theta);
            positions[i + 2] = radius * Math.cos(phi);

            // Add random colors for each particle
            colors[i] = Math.random(); // Red
            colors[i + 1] = Math.random(); // Green
            colors[i + 2] = Math.random(); // Blue
        }

        particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        particlesGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3)); // Apply colors

        // Particle material with vertex colors enabled
        const particlesMaterial = new THREE.PointsMaterial({
            size: 0.07, // Slightly bigger particles
            vertexColors: true, // Use colors for each particle
            transparent: true,
            opacity: 0.7, // Add a little transparency for subtle effect
        });

        // Create particles system
        const particlesMesh = new THREE.Points(particlesGeometry, particlesMaterial);
        scene.add(particlesMesh);

        camera.position.z = 7;

        // Track mouse movement
        let mouseX = 0;
        let mouseY = 0;
        const windowHalfX = window.innerWidth / 2;
        const windowHalfY = window.innerHeight / 2;

        document.addEventListener('mousemove', (event) => {
            mouseX = (event.clientX - windowHalfX) / windowHalfX; // Normalize to range -1 to 1
            mouseY = (event.clientY - windowHalfY) / windowHalfY; // Normalize to range -1 to 1
        });

        // Create the background text
        const textDiv = document.createElement('div');
        textDiv.innerText = 'Volmint Technologies';
        textDiv.style.position = 'absolute';
        textDiv.style.top = '10%'; // Position text near the top
        textDiv.style.left = '50%';
        textDiv.style.transform = 'translate(-50%, 0)'; // Center horizontally
        textDiv.style.color = 'rgba(255, 255, 255, 0.7)'; // Light white text color
        textDiv.style.fontSize = '5em'; // Large text size
        textDiv.style.fontWeight = 'bold'; // Optional
        textDiv.style.pointerEvents = 'none'; // Prevent interaction
        textDiv.id = 'background-text'; // Set an ID for easy access
        container.appendChild(textDiv);

        // Function to handle text visibility based on screen size
        const handleTextVisibility = () => {
            const textElement = document.getElementById('background-text')!;
            if (window.innerWidth < 700) {
                textElement.style.display = 'none'; // Hide text on small screens
            } else {
                textElement.style.display = 'block'; // Show text on larger screens
            }
        };

        // Initial check for text visibility
        handleTextVisibility();

        // Add resize event listener
        window.addEventListener('resize', handleTextVisibility);

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);

            // Adjust rotation based on mouse position (slower rotation)
            const rotationSpeed = 0.00001; // Reduced rotation speed
            particlesMesh.rotation.y += rotationSpeed + mouseX * 0.001; // Further reduce speed for mouse X
            particlesMesh.rotation.x += mouseY * 0.001; // Further reduce speed for mouse Y

            // Create gradient effect for background
            const gradient = backgroundMaterial.color.clone().lerp(gradientColors[1], 0.5); // Modify as needed
            backgroundMaterial.color.copy(gradient);

            renderer.render(scene, camera);
        };

        animate();

        // Handle window resize
        window.addEventListener('resize', () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        });
    }

    checkLogin() {
        if (StorageService.getItem('accessToken') && StorageService.getItem('self')) {
            this.globalService.setAccessToken(StorageService.getItem('accessToken'));
            this.globalService.setSelf(StorageService.getItem('self'));
            this.user = StorageService.getItem('self');
            this.reloadSelf();
        } else {
            this.router.navigateByUrl('auth/login');
        }
    }

    reloadSelf() {
        this.authService.self({all: true}).subscribe((data) => {
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type,
                'unique_id': data.unique_id
            });
            this.router.navigateByUrl('/dashboard');
        }, e => {
            StorageService.clearAll();
            this.router.navigateByUrl('auth/login');
        });
    }

    login() {
        this.submittedLogin = true;
        if (!this.loginForm.valid) {
            return;
        }
        if (this.submittedLogin) {
            if (this.checkLoggedInStatus()) {
                this.setSelfData();
                this.connectToSocket();
                this.router.navigateByUrl('/dashboard');
            } else {
                this.authService.login(this.loginForm.getRawValue()).subscribe(data => {
                    if (data.id) {
                        this.globalService.setAccessToken(data.token);
                        this.globalService.setSelf({
                            'name': data.name,
                            'email': data.email,
                            'contact': data.contact,
                            'user_id': data.id,
                            'username': data.username,
                            'user_type': data.user_type,
                            'unique_id': data.unique_id
                        });
                        this.user = StorageService.getItem('self');
                        this.connectToSocket();
                        this.router.navigateByUrl('/dashboard');
                        this.loginForm.reset();
                    }
                }, error => {
                    this.isBusy = false;
                    this.submittedLogin = false;
                });
            }
        }
    }

    showPassword() {
        this.show = !this.show
    }

    checkLoggedInStatus() {
        const accessToken = StorageService.getItem('accessToken');
        return accessToken && accessToken !== 'null';
    }

    setSelfData() {
        this.authService.self({all: true}).subscribe((data) => {
            this.isBusy = false;
            this.globalService.setSelf({
                'name': data.name,
                'user_id': data.id,
                'username': data.username,
                'email': data.email,
                'contact': data.contact,
                'user_type': data.user_type
            });
            return;
        }, e => {
            this.isBusy = false;
            StorageService.clearAll();
        });
    }

    connectToSocket() {
        this.window.Pusher = Pusher;
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: '29f3edbd069470d91803',
            cluster: 'ap2',
            // wsHost: window.location.hostname,
            // wsPort: 6001,
            forceTLS: true,
            // disableStats: true,
        });
        this.listenToChat();
    }

    listenToChat() {
        this.echo.channel(`chat_app_${this.user?.user_id}`)
            .listen('.message.sent', (data: any) => {
                console.log('at login component ', data);
                this.dataService.sendMessage(data);
                this.playNotificationSound();
            });
    }

    playNotificationSound() {
        const audio = new Audio();
        audio.src = 'assets/sounds/notification.mp3'; // Path to your sound file
        audio.load();
        audio.play();
    }
}
