import {Injectable} from '@angular/core';
import {UserService} from "./user.service";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: 'root'
})
export class WindowEventService {
    constructor(private userService: UserService) {
        this.addUnloadEvent();
    }

    addUnloadEvent() {
        window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
    }

    handleBeforeUnload(event: Event) {
        // Your logic to handle the event
        this.updateLoginStatusInactive();
    }

    updateLoginStatusInactive() {
        const data = StorageService.getItem('self');
        this.userService.loginStatus(data.unique_id, {'is_login_web': 'INACTIVE'}).subscribe(data => {
        }, error => {
        });

    }
}
