import {Routes} from "@angular/router";

export const content: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)
    },
    {
        path: "career",
        loadChildren: () => import("../../components/career/career.module").then((m) => m.CareerModule)
    },
    {
        path: "employee",
        loadChildren: () => import("../../components/employee/employee.module").then((m) => m.EmployeeModule)
    },
    {
        path: "blog",
        loadChildren: () => import("../../components/blog/blog.module").then((m) => m.BlogModule)
    },
    {
        path: "meta-tags",
        loadChildren: () => import("../../components/meta-tags/meta-tags.module").then((m) => m.MetaTagsModule)
    },
    {
        path: "departments",
        loadChildren: () => import("../../components/departments/departments.module").then((m) => m.DepartmentsModule)
    },
    {
        path: "access",
        loadChildren: () => import("../../components/access/access.module").then((m) => m.AccessModule)
    },
    {
        path: "contact-us",
        loadChildren: () => import("../../components/contact-us/contact-us.module").then((m) => m.ContactUsModule)
    },
    {
        path: "lead",
        loadChildren: () => import("../../components/leads/leads.module").then((m) => m.LeadsModule)
    },
    {
        path: "career-data",
        loadChildren: () => import("../../components/career-data/career-data.module").then((m) => m.CareerDataModule)
    },
    {
        path: "attendance",
        loadChildren: () => import("../../components/attendance/attendance.module").then((m) => m.AttendanceModule)
    },
    {
        path: "leave",
        loadChildren: () => import("../../components/leave/leave.module").then((m) => m.LeaveModule)
    },
    {
        path: "holiday",
        loadChildren: () => import("../../components/holiday/holiday.module").then((m) => m.HolidayModule)
    },
    {
        path: "profile",
        loadChildren: () => import("../../components/profile/profile.module").then((m) => m.ProfileModule)
    },
    {
        path: "device",
        loadChildren: () => import("../../components/device/device.module").then((m) => m.DeviceModule)
    },
    {
        path: "chat",
        loadChildren: () => import("../../components/chat/chat.module").then((m) => m.ChatModule)
    },
    {
        path: "transaction",
        loadChildren: () => import("../../components/transactions/transactions.module").then((m) => m.TransactionsModule)
    },
    {
        path: "partner",
        loadChildren: () => import("../../components/partner/partner.module").then((m) => m.PartnerModule)
    },
];
