import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from "./shared/shared.module";
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
// // for HttpClient import:
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
// // for Router import:
import {LoadingBarRouterModule} from '@ngx-loading-bar/router';
// // for Core import:
import {LoadingBarModule} from '@ngx-loading-bar/core';
import {CookieService} from 'ngx-cookie-service';
import {AppComponent} from './app.component';
import {LoginComponent} from './auth/login/login.component';
import {OverlayModule} from '@angular/cdk/overlay';
import {SnotifyModule, SnotifyService, ToastDefaults} from "ng-snotify";
import {AlertService} from "./shared/services/alert.service";
import {HtpInterceptor} from "./shared/services/interceptor.service";
import {GlobalService} from "./shared/services/global.service";
import {ImageCropperModule} from "ngx-image-cropper";
import {DatePipe} from "@angular/common";
import {AboutUsComponent} from './components/about-us/about-us.component';
import {ContactUsComponent} from './components/contact-us/contact-us.component';
import {CareerDataComponent} from './components/career-data/career-data.component';
import {EmployeeComponent} from './components/employee/employee.component';
import {DepartmentsComponent} from './components/departments/departments.component';
import {AccessComponent} from './components/access/access.component';
import {ProfileComponent} from './components/profile/profile.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {CKEditorModule} from "@ckeditor/ckeditor5-angular";
import {ClickOutsideDirective} from './click-outside.directive';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        AboutUsComponent,
        EmployeeComponent,
        DepartmentsComponent,
        AccessComponent,
        ProfileComponent,
        ClickOutsideDirective,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        OverlayModule,
        SharedModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        SnotifyModule,
//     // for HttpClient use:
        LoadingBarHttpClientModule,
//     // for Router use:
        LoadingBarRouterModule,
//     // for Core use:
        LoadingBarModule,
        ImageCropperModule,
        NgSelectModule,
        CKEditorModule,
    ],
    providers: [
        HtpInterceptor,
        GlobalService,
        CookieService,
        AlertService,
        {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
        SnotifyService,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
