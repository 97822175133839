import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {BaseService} from "./base.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
    providedIn: 'root'
})
export class DeviceService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    add(data: any): Observable<any> {
        return this.postRequest(AppUrl.DEVICE(), data);
    }

    get(data: any): Observable<any> {
        return this.getRequestWithoutErrorMessage(AppUrl.DEVICE(), data);
    }

    detail(uniqueId: any): Observable<any> {
        return this.getRequest(AppUrl.DEVICE(uniqueId));
    }

    update(uniqueId: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.DEVICE(uniqueId), data);
    }

    delete(uniqueId: any): Observable<any> {
        return this.deleteRequest(AppUrl.DEVICE(uniqueId));
    }

    updateStatus(uniqueId: any, data: any): Observable<any> {
        return this.putRequest(AppUrl.DEVICE_STATUS(uniqueId), data);
    }

    getAssigned(data: any): Observable<any> {
        return this.getRequest(AppUrl.DEVICE_ASSIGNED(), data);
    }

    assignedDevice(uniqueId: any, data: any){
        return this.putRequest(AppUrl.ASSIGNED_DEVICE(uniqueId),data);
    }

    dismissAssignedDevice(uniqueId: any, data: any){
        return this.putRequest(AppUrl.DISMISS_ASSIGNED_DEVICE(uniqueId),data);
    }
}
