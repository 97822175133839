import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get ADMIN_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get LOGIN(): string {
        return AppUrl.ADMIN_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.ADMIN_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.ADMIN_URL + 'update-self';
    }

    static UPDATE_PASSWORD(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'update-password/' + uniqueId;
        }
    }

    static UPDATE_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor-status/' + uniqueId;
        }
    }

    static LOGOUT(): string {
        return AppUrl.ADMIN_URL + 'logout';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.ADMIN_URL + 'file-upload';
    }

    static CHAT_FILE_UPLOAD(): string {
        return AppUrl.ADMIN_URL + 'file-upload-chat';
    }

    static STATES(): string {
        return AppUrl.ADMIN_URL + 'states';
    }


    static USERS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'users/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'users';
        }
    }

    static USERS_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'users-status/' + id;
        }
    }

    static USERS_DEPARTMENT(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'users-department/' + id;
        }
    }

    static USER(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'user/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'user';
        }
    }

    static LOGIN_STATUS(id: any): string {
        return AppUrl.ADMIN_URL + 'login-status/' + id;
    }

    static SELF_PROFILE_PASSWORD(): string {
        return AppUrl.ADMIN_URL + 'update-user-password';
    }

    static BLOGS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'blogs';
        }
    }

    static BLOGS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'blogs-count';
    }

    static BLOG_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-status/' + id;
        }
    }

    static BLOG_BANNER_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-banner/' + id;
        }
    }

    static POFILE_IMAGE_UPDATE(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'id-card-image/' + unique_id;
        }
    }

    static QR_CODE_UPDATE(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'qr-code/' + unique_id;
        }
    }

    static BLOG_COVER_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-cover/' + id;
        }
    }

    static BLOG_CONTENT_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-content/' + id;
        }
    }

    static CAREERS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'career/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'career';
        }
    }

    static METATAG(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'meta-tag/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'meta-tag';
        }
    }

    static EMPLOYEES(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'id-card/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'id-card';
        }
    }

    static EMPLOYEE_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'users-status/' + id;
        }else{
            return AppUrl.ADMIN_URL + 'users-status';
        }
    }

    static EMPLOYEE(unique_id: any = 0): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'user/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'user';
        }
    }

    static DEPARTMENT(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'department/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'department';
        }
    }

    static PERMISSION(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'permission/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'permission';
        }
    }

    static PERMISSIONS_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'permission-status/' + unique_id;
        }
    }

    static SUB_PERMISSIONS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'sub-permission/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'sub-permission';
        }
    }

    static SUB_PERMISSIONS_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'sub-permission-status/' + unique_id;
        }
    }


    static ROLES(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'role/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'role';
        }
    }

    static ROLES_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'role-status/' + unique_id;
        }
    }

    static ROLE_PERMISSIONS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'role-permission/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'role-permission';
        }
    }

    static ROLE_PERMISSIONS_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'role-permission-status/' + unique_id;
        }
    }

    static DELETE_ALL_ROLE_PERMISSIONS(unique_id): string {
        return AppUrl.ADMIN_URL + 'delete-role-permission-by-role-id/' + unique_id;
    }

    static DEPARTMENT_STATUS(unique_id): string {
        return AppUrl.ADMIN_URL + 'department-status/' + unique_id;
    }

    static JOBOPENING(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'job-opening/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'job-opening';
        }
    }

    static JOBOPENING_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'job-opening-status/' + id;
        }
    }

    static CONTACTUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.API_URL + 'contact-us/' + unique_id;
        } else {
            return AppUrl.API_URL + 'contact-us';
        }
    }

    static CAREERSDATA(unique_id?): string {
        if (unique_id) {
            return AppUrl.API_URL + 'career/' + unique_id;
        } else {
            return AppUrl.API_URL + 'career';
        }
    }


    static CAREERS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'career-count';
    }

    static CAREER_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'career-status/' + unique_id;
        }
    }

    static ATTENDANCE(data = {}): string {
        return AppUrl.ADMIN_URL + 'attendance';
    }

    static ATTENDANCE_DOWNLOAD():String{
        return AppUrl.ADMIN_URL + 'attendance-download';
    }

    static ACCESS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'role/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'role'
        }
    };

    static ROLE_STATUS(id?): string {
        return AppUrl.ADMIN_URL + 'role-status/' + id;
    }

    static TOTAL_ROLE(): string {
        return AppUrl.ADMIN_URL + 'role-count';
    }

    static Role_PERMISSION(): string {
        return AppUrl.ADMIN_URL + 'role-permission';
    }

    static PERMISSION_STATUS(id?): string {
        return AppUrl.ADMIN_URL + 'permission-status/' + id;
    }


    static SUB_PERMISSION_STATUS(id): string {
        return AppUrl.ADMIN_URL + 'sub-permission-status/' + id
    }

    static TOTAL_PERMISSION(): string {
        return AppUrl.ADMIN_URL + 'permission-count';
    }

    static ROLE_PERMISSION(): string {
        return AppUrl.ADMIN_URL + 'user-role-permission'
    }

    static ROLE_PERMISSION_UPDATE(): String {
        return AppUrl.ADMIN_URL + 'role-permission'
    }

    static DELETE_ALL_ROLE_PERMISSION(id): string {
        return AppUrl.ADMIN_URL + 'delete-role-permission-by-role-id/' + id;
    }

    static PERMISSION_DETAIL(id): string {
        return AppUrl.ADMIN_URL + 'permission-detail/' + id;
    }

    static LEAVE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'leave/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'leave';
        }
    }

    static HOLIDAY(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'holiday/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'holiday';
        }
    }

    static DEVICE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'device/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'device';
        }
    }

    static DEVICE_STATUS(id?): string {
        return AppUrl.ADMIN_URL + 'device-status/' + id;
    }

    static DEVICE_ASSIGNED(): string {
        return AppUrl.ADMIN_URL + 'device-assign';
    }

    static ASSIGNED_DEVICE(uniqueId: any): string {
        return AppUrl.ADMIN_URL + 'device-assign/' + uniqueId;
    }

    static DISMISS_ASSIGNED_DEVICE(uniqueId: any): string {
        return AppUrl.ADMIN_URL + 'device-dismiss/' + uniqueId;
    }


    static CHAT(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'chat/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'chat';
        }

    }

    static CHAT_UNREAD(): string {
        return AppUrl.ADMIN_URL + 'chat-unread';
    }

    static CHAT_GROUP(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'chat-group/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'chat-group';
        }
    }

    static GROUP_CHAT(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'group-chat/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'group-chat';
        }
    }

    static CHAT_FORWARD(): string {
        return AppUrl.ADMIN_URL + 'chat-forward';
    }

    static PIN_CHAT(): string {
        return AppUrl.ADMIN_URL + 'pin-chat';
    }

    static PIN_CHAT_GROUP(): string {
        return AppUrl.ADMIN_URL + 'pin-chat-group';
    }

    static FILTER_CHAT(): string {
        return AppUrl.ADMIN_URL + 'filter-chat';
    }

    static FILTER_GROUP_CHAT(): string {
        return AppUrl.ADMIN_URL + 'filter-group-chat';
    }

    static INVOICE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'invoice/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'invoice';
        }
    }

    static INVOICE_PDF(): string {
        return AppUrl.ADMIN_URL + 'invoice-pdf';
    }

    static PREVIEW_INVOICE_PDF(): string {
        return AppUrl.ADMIN_URL + 'preview-invoice-pdf';
    }

    static TEMPLATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'template/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'template';
        }
    }

    static TEMPLATE_PREVIEW():string{
        return AppUrl.ADMIN_URL + 'template-preview';
    }

    static PROFORMA_INVOICE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'proforma-invoice/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'proforma-invoice';
        }
    }

    static PROFORMA_INVOICE_PDF(): string {
        return AppUrl.ADMIN_URL + 'proforma-invoice-pdf';
    }
}
