<div class="login-page">
    <!-- Three.js animation container -->
    <div id="threejs-container"></div>

    <!-- Centered Login Form -->
    <div class="login-form-container">
        <div class="row ">
            <div class="col-md-6 d-sm-none d-md-block mb-0">
                <div class="quote-container">
                    <blockquote class="blockquote text-start">
                        <p class="mb-0 text-white font-size-20">"{{ quote }}"</p>
                        <footer class="blockquote-footer text-white m-1">{{ author }}</footer>
                    </blockquote>
                </div>
            </div>

            <div class="col-md-6 mt-0">
                <div class="login-card">
                    <div>
                        <div class="login-main">
                            <div>
                                <a class="logo" routerLink="/">
                                    <img class="img-fluid for-light" src="assets/images/logo/logo.png"
                                         alt="looginpage"/>
                                    <img class="img-fluid for-dark" src="assets/images/logo/logo_dark.png"
                                         alt="looginpage"/>
                                </a>
                            </div>
                            <form class="theme-form" [formGroup]="loginForm">
                                <h4>Sign in to account</h4>
                                <p>Enter your username & password to login</p>
                                <div class="form-group">
                                    <label class="col-form-label">Username</label>
                                    <input class="form-control" type="text" placeholder="Volmint"
                                           formControlName="username"/>
                                    <div *ngIf="loginForm.controls.username.touched && loginForm.controls.username.errors?.required"
                                         class="text text-danger mt-1">Username is required
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-form-label">Password</label>
                                    <input class="form-control" [type]="show ? 'text' : 'password'"
                                           formControlName="password" placeholder="*********"/>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span
                                            class="show"></span>
                                    </div>
                                    <div class="show-hide" (click)="showPassword()" *ngIf="show"><span
                                            class="Hide"></span>
                                    </div>
                                    <div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                                         class="text text-danger mt-1">Password is required
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="checkbox p-0">
                                        <input id="checkbox1" type="checkbox"/>
                                        <label class="text-muted" for="checkbox1">Remember password</label>
                                    </div>
                                    <a [routerLink]="'/authentication/forgot-password'" class="link">Forgot
                                        password?</a>
                                    <div class="text-end mt-3">
                                        <button class="btn btn-primary d-block w-100" [disabled]="!loginForm.valid"
                                                (click)="login()" type="button"><span>Sign in</span></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

