<div class="fixed-breadcrumb">
    <app-breadcrumb [active_item]="'ID Cards'" [icon]="'fa-user-shield fas'"

                    [items]="['Employee']"
                    [title]="'Id Cards'"
                    [url]="'/employee'"></app-breadcrumb>
</div>

<div class="row">
    <div class="col-12 px-4">
        <div class="card p-0" style="border-radius: 8px !important;">
            <div class="card-body b-0" style="padding: 10px !important;">
                <form [formGroup]="searchForm">
                    <div class="row">
                        <div class="col-md-3 col-sm-12 my-2">
                            <input type="text" class="date-picker form-control w-100" formControlName="name"
                                   placeholder="Name of the Employee"/>
                        </div>
                        <div class="col-md-3 col-sm-12 my-2">
                            <input type="text" class="date-picker form-control w-100" formControlName="email"
                                   placeholder="Email of the Employee"/>
                        </div>
                        <div class="col-md-3 col-sm-12 my-2">
                            <input type="text" class="date-picker form-control w-100" formControlName="contact"
                                   placeholder="Contact of the Employee"/>
                        </div>
                        <div class="col-md-3 col-12 my-2">
                            <button class="btn btn-primary w-100" (click)="search()">Search</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <ng-template #idcardModal>
        <div class="modal-content" style="display: flex; flex-direction: column; height: 100%; max-height: 90vh;">
            <div class="modal-header custom-header py-4">
                <div class="row w-100">
                    <div class="col-9 text-start">
                        <h4 class="modal-title font-weight-bold primary-blue-color">Update Id Card</h4>
                    </div>
                    <div class="col-3 text-end">
                        <i class="fa fa-window-close text-danger font-size-20" (click)="closeModal()"></i>
                    </div>
                </div>
            </div>
            <div class="modal-body" style="flex: 1; overflow-y: auto; overflow-x: hidden;">
                <form [formGroup]="updateIdcardForm">
                    <div class="row">
                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Designation</span>
                            <input type="text" class="form-control input-group" formControlName="designation"
                                   placeholder="designation"/>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Date Of Joining</span>
                            <input type="date" class="form-control w-100" formControlName="date_of_joining"
                                   placeholder="Joining Date"/>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Father Name</span>
                            <input type="text" class="form-control input-group" formControlName="father_name"
                                   placeholder="Father Name"/>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Date Of Birth</span>
                            <input type="date" class="form-control" formControlName="dob" placeholder="Date of Birth"/>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Gender</span>
                            <select class="form-control dropdown-basic" formControlName="gender">
                                <option value="">Select Gender</option>
                                <option value="MALE">MALE</option>
                                <option value="FEMALE">FEMALE</option>
                            </select>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Highest Qualification</span>
                            <input type="text" class="form-control input-group" formControlName="highest_qualification"
                                   placeholder="Qualification"/>
                        </div>

                        <div class="col-xl-12 mb-3">
                            <span class="primary-blue-color mb-2">Marital Status</span>
                            <input type="text" class="form-control" formControlName="marital_status"
                                   placeholder="Marital Status"/>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer custom-header d-flex justify-content-end py-4">
                <button (click)="updateIdCard()"
                        aria-label="Close"
                        class="btn btn-primary color-white me-2">
                    <i class="fas fa-edit mr-1"></i> Update
                </button>
            </div>
        </div>
    </ng-template>

    <div class="row">
        <div class="col-sm-12">
            <div class="card table-card card-modified">
                <div class="card-header table-card-header p-25px">
                    <h5 class="text-start primary-blue-color">Id Card List</h5>
                </div>
                <div class="card-block row">
                    <div class="col-sm-12 col-lg-12 col-xl-12">
                        <div class="table-responsive" style="overflow-x: auto; margin: 20px 0;">
                            <table class="custom-table">
                                <thead>
                                <tr>
                                    <th class="text-center" scope="col" width="10%">Sno.</th>
                                    <th class="text-center" scope="col" width="10%">Emp No</th>
                                    <th class="text-center" scope="col" width="10%">Name</th>
                                    <th class="text-center" scope="col" width="10%">Email</th>
                                    <th class="text-center" scope="col" width="10%">Contact</th>
                                    <th class="text-center" scope="col" width="10%">Address</th>
                                    <th class="text-center" scope="col" width="10%">Designation</th>
                                    <th class="text-center" scope="col" width="10%">Date Of Join</th>
                                    <th class="text-center" scope="col" width="10%">Profile Image</th>
                                    <th class="text-center" scope="col" width="10%">QR Code</th>
                                    <th class="text-center" scope="col" width="5%">Action</th>
                                </tr>
                                </thead>
                                <tbody *ngIf="employees && employees.length > 0; else noDataToShow">
                                <tr *ngFor="let employee of employees; let i = index">
                                    <td class="text-center">
                                        {{ (pagination.page - 1) * (pagination.perpage ? pagination.perpage : 0) + i + 1 }}
                                        .
                                    </td>
                                    <td class="text-center">{{ employee?.emp_no }}</td>
                                    <td class="text-center">{{ employee?.user?.name }}</td>
                                    <td class="text-center">{{ employee?.user?.email }}</td>
                                    <td class="text-center">{{ employee?.user?.contact }}</td>
                                    <td class="text-center">{{ employee?.user?.address }}</td>
                                    <td class="text-center">{{ employee?.designation }}</td>
                                    <td class="text-center">{{ employee?.date_of_joining }}</td>
                                    <td class="text-center">
                                        <div class="btn-group" role="group">
                                            <button *ngIf="employee?.profile_image;"
                                                    (click)="openPrifileImage(employee)"
                                                    class="btn btn-primary color-white"
                                                    title="View Profile Image">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                            <button (click)="bannerUpload.click()" class="btn btn-primary color-white"
                                                    title="Upload">
                                                <i class="fas fa-cloud-upload-alt"></i>
                                            </button>
                                        </div>
                                        <input #bannerUpload (change)="onFileChange($event, 'PROFILE_IMAGE', employee)"
                                               class="form-control-file display-hidden" type="file">
                                    </td>
                                    <td class="text-center">
                                        <div class="btn-group" role="group">
                                            <button *ngIf="employee?.qr_code;" (click)="openQrCode(employee)"
                                                    class="btn btn-primary color-white"
                                                    title="View QR Code">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                            <button (click)="qrUpload.click()" class="btn btn-primary color-white"
                                                    title="Upload" type="button">
                                                <i class="fas fa-cloud-upload-alt"></i>
                                            </button>
                                        </div>
                                        <input #qrUpload (change)="onFileChange($event, 'QR_CODE', employee)"
                                               class="form-control-file display-hidden" type="file">

                                    </td>
                                    <td class="text-center" style="width: 10rem;">
                                        <i (click)="openUpdateIdcardModal(idcardModal, employee)"
                                           aria-hidden="true"
                                           class="fa-solid fa-edit f_20 text-info cursor-pointer"
                                           title="Edit" style="margin-right: 5px;"></i>
                                    </td>
                                </tr>
                                </tbody>
                                <ng-template #noDataToShow>
                                    <tbody>
                                    <tr>
                                        <td class="text-center" colspan="11">
                                            <span>No data to show! 😵</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                </ng-template>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card-footer table-card-footer">
                    <div class="pagination-top">
                        <ngb-pagination (pageChange)="onPageChange($event)" [(page)]="pagination.page"
                                        [boundaryLinks]="true"
                                        [collectionSize]="pagination.total"
                                        [maxSize]="5"
                                        [pageSize]="pagination.perpage"
                                        class="d-flex justify-content-center">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
