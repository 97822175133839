// click-outside.directive.ts
import {Directive, ElementRef, HostListener, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent): void {
        // Check if the click is outside of the element
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.clickOutside.emit();
        }
    }
}
