import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class AccessService extends BaseService {

  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  addRole(data): Observable<any> {
    return this.postRequest(AppUrl.ACCESS(), data)

  }
  getRole(data): Observable<any> {
    return this.getRequest(AppUrl.ACCESS(), data);
  };
  updateRole(id, data): Observable<any> {
    return this.putRequest(AppUrl.ACCESS( id), data);
  }
  status(id, data):Observable<any>{
    return this.putRequest(AppUrl.ROLE_STATUS(id), data)
  }
  deleteRole(id): Observable<any> {
    return this.deleteRequest(AppUrl.ACCESS(id));
  };
  totalRole(): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_ROLE());
  };

  addPermisson(data): Observable<any> {
    return this.postRequest(AppUrl.PERMISSION(), data)

  }
  getPermission(data): Observable<any> {
    return this.getRequest(AppUrl.PERMISSION(), data);
  };
  RolePermissionGet(data:{}):Observable<any>{
    return this.getRequest(AppUrl.Role_PERMISSION(), data);
  }
  updatePermission(id, data): Observable<any> {
    return this.putRequest(AppUrl.PERMISSION( id), data);
  }
  permissionStatus(id, data):Observable<any>{
    return this.putRequest(AppUrl.PERMISSION_STATUS(id), data)
  }
  deletePermission(id): Observable<any> {
    return this.deleteRequest(AppUrl.PERMISSION(id));
  };
  totalPermision(): Observable<any> {
    return this.getRequest(AppUrl.TOTAL_PERMISSION());
  };

  getRolePermission(params){
    return this.getRequest(AppUrl.ROLE_PERMISSION(), params)
  }

  updateRolePermission(data):Observable<any>{
    return this.putRequest(AppUrl.ROLE_PERMISSION_UPDATE(), data)
  }
  deleteAllRolePermission(id):Observable<any>{
    return this.deleteRequest(AppUrl.DELETE_ALL_ROLE_PERMISSION(id))
  }
  permission_Detail(id):Observable<any>{
    return this.getRequest(AppUrl.PERMISSION_DETAIL(id))
  }
  addSubPermission(data):Observable<any>{
    return this.postRequest(AppUrl.SUB_PERMISSIONS(), data)
  }
  subPermissionUpdate( id, data):Observable<any>{
    return this.putRequest(AppUrl.SUB_PERMISSIONS(id), data)
  }
  subPermissionStatus(id, data):Observable<any>{
    return this.putRequest(AppUrl.SUB_PERMISSION_STATUS(id), data)
  }
  deleteSubPermission(id): Observable<any> {
    return this.deleteRequest(AppUrl.SUB_PERMISSIONS(id));
  };
}

