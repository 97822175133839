import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Router} from "@angular/router";
import {JobopeningService} from "../../shared/services/jobopening.service";
import {StorageService} from "../../shared/services/storage.service";
import {EmployeesService} from "../../shared/services/employees.service";
import {AlertService} from "../../shared/services/alert.service";
import {AccessService} from "../../shared/services/access.service";
import {Helper} from "../../shared/constants/helper";
import {NavService} from "../../shared/services/nav.service";
import {DeviceService} from "../../shared/services/device.service";

@Component({
    selector: 'app-employee',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
    modalType: any;
    modalTitle: any;
    viewContentData: any;
    searchForm: FormGroup;
    updateIdcardForm: FormGroup;
    employees: any = [];
    onFileChanged: any;
    user: any;
    pagination = {
        page: 1,
        total: null,
        perpage: 15,
        pages: null
    };
    closeResult = '';
    submitted: any = false;
    userData: any;
    subPermissionArray: any;
    selectedData: any;

    constructor(private modalService: NgbModal,
                private router: Router,
                private employeeService: EmployeesService,
                private jobOpeningService: JobopeningService,
                private fb: FormBuilder,
                private alertService: AlertService,
                private permissionService: AccessService,
                private navService: NavService,) {
        this.user = StorageService.getItem('self');

    }

    ngOnInit(): void {
        if (this.user.user_id !== 1) {
            this.getUserDetail();
        }
        this.refresh();
    }

    refresh() {
        this.updateIdcardForm = this.fb.group({
            'designation': ['', Validators.required],
            'date_of_joining': ['', Validators.required],
            'father_name': ['', Validators.required],
            'dob': ['', Validators.required],
            'gender': ['', Validators.required],
            'highest_qualification': ['', Validators.required],
            'marital_status': ['', Validators.required],
        })
        this.searchForm = this.fb.group({
            'name': [''],
            'username': [''],
            'email': [''],
            'contact': [''],
        });
        this.get();
    }

    getUserDetail() {
        if (this.user && this.user.user_id) {
            this.permissionService.RolePermissionGet({'userid': this.user.user_id}).subscribe(data => {
                if (data) {
                    this.userData = data.data;
                    let subPermissionsArr = [];
                    if (this.userData) {
                        this.userData.forEach(rolePermission => {
                            subPermissionsArr.push(rolePermission['sub_permission']['meta']);
                        });
                        this.subPermissionArray = subPermissionsArr;
                    }
                    this.getNavService(this.router.url);
                }
            });
        }
    }

    getNavService(url) {
        this.navService.MENUITEMS.forEach(menu => {
            if (menu && menu.availableTo) {
                if (menu['path'] == url) {
                    let checkMenuPermission = Helper.findCommonElements(menu.availableTo, this.subPermissionArray);
                    if (!checkMenuPermission) {
                        this.router.navigateByUrl('/general/home');
                    }
                } else {
                    menu.children[0].children.forEach(child => {
                        if (child.path == url) {
                            let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, this.subPermissionArray);
                            if (!checkSubMenuPermission) {
                                this.router.navigateByUrl('/general/home');
                            }
                        }
                    })
                }
            }
        })
    }

    get(params: any = {}) {
        params.page = this.pagination.page;
        params.per_page = this.pagination.perpage;
        this.employeeService.get(params).subscribe(data => {
            if (data && data['data'].length > 0) {
                this.employees = data['data'];
            } else {
                this.employees = [];
            }
            this.pagination = {
                page: data.current_page,
                total: data.total,
                perpage: data.per_page,
                pages: data.last_page,
            };
        }, error => {
            this.employees = [];
        });
    }

    viewContent(content, data, msg: any) {
        this.modalType = 'BLOG_CONTENT';
        this.modalTitle = msg;
        this.viewContentData = data;
        this.modalService.open(content, {'size': 'lg'});
    }

    search() {
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
        };
        this.get(params);
    }

    onPageChange(page) {
        this.pagination.page = page;
        const params = {
            'name': (this.searchForm.value && this.searchForm.value.name) ? this.searchForm.value.name : '',
            'username': (this.searchForm.value && this.searchForm.value.username) ? this.searchForm.value.username : '',
            'email': (this.searchForm.value && this.searchForm.value.email) ? this.searchForm.value.email : '',
            'contact': (this.searchForm.value && this.searchForm.value.contact) ? this.searchForm.value.contact : '',
        };
        this.get(params);
    }


    /* edit(Unique_id) {
       this.router.navigate(['career/add'], {state: Unique_id});
     }*/
    edit(Unique_id) {
        this.router.navigate(['employee/add'], {
            queryParams: {unique_id: Unique_id}
        });
    }

    delete(Unique_id) {
        this.employeeService.delete(Unique_id).subscribe(data => {
            this.get();
        }, error => {
        });
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    openPrifileImage(data) {
        window.open(data['profile_image']['url'], '_blank');
    }

    openQrCode(data) {
        window.open(data['qr_code']['url'], '_blank');
    }

    onFileChange(e, type, idcard) {
        if (type === 'PROFILE_IMAGE' || type === 'QR_CODE') {
            this.onFileChanged = e;
            this.employeeService.fileUploadAdmin({
                'type': type,
                'fileType': 'NORMAL',
            }, this.onFileChanged).subscribe(data => {
                if (type === 'PROFILE_IMAGE') {
                    this.updateProfileImage(idcard, data['data']);
                } else if (type === 'QR_CODE') {
                    this.updateQrCode(idcard, data['data']);
                } else {
                    this.get();
                }
            }, error => {
                this.alertService.showErrors('Something went wrong, please try again');
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    updateProfileImage(idcard, data) {
        this.employeeService.updateProfileImage(idcard.unique_id, {'profile_image': data.id}).subscribe(imageData => {
            this.submitted = false;
            this.employees = this.employees.map(item => (item.unique_id == idcard.unique_id ? {
                ...item,
                profile_image: data
            } : item));
        }, error => {
            this.submitted = false;
        });
    }

    updateQrCode(idcard: any, data: any) {
        this.employeeService.updateQrCode(idcard.unique_id, {'qr_code': data.id}).subscribe(imageData => {
            this.submitted = false;
            this.employees = this.employees.map(item => (item.unique_id == idcard.unique_id ? {
                ...item,
                qr_code: data
            } : item));
        }, error => {
            this.submitted = false;
        });
    }


    openUpdateIdcardModal(content: any, data: any) {
        this.selectedData = data;
        this.modalService.open(content, {size: 'xl'});
        this.updateIdcardForm.patchValue({
            'designation': data?.designation,
            'date_of_joining': data?.date_of_joining,
            'father_name': data?.father_name,
            'dob': data?.dob,
            'gender': data?.gender,
            'highest_qualification': data?.highest_qualification,
            'marital_status': data?.marital_status,
        })
    }

    updateIdCard() {
        if (!this.updateIdcardForm.valid) {
            this.alertService.showErrors('All fields are Required');
            return;
        }
        const params = {
            'designation': this.updateIdcardForm.value.designation,
            'date_of_joining': this.updateIdcardForm.value.date_of_joining,
            'father_name': this.updateIdcardForm.value.father_name,
            'dob': this.updateIdcardForm.value.dob,
            'gender': this.updateIdcardForm.value.gender,
            'highest_qualification': this.updateIdcardForm.value.highest_qualification,
            'marital_status': this.updateIdcardForm.value.marital_status,
        };
        this.employeeService.update(this.selectedData?.unique_id, params).subscribe(data => {
            this.modalService.dismissAll();
            this.employees = this.employees.map(item => (item.unique_id == this.selectedData.unique_id ? {
                ...item,
                designation: params.designation,
                date_of_joining: params.date_of_joining,
                father_name: params.father_name,
                dob: params.dob,
                gender: params.gender,
                highest_qualification: params.highest_qualification,
                marital_status: params.marital_status
            } : item));
            this.selectedData = null;
        }, error => {

        })
    }

    closeModal() {
        this.modalService.dismissAll();
    }
}
