import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";

@Injectable({
    providedIn: 'root'
})
export class UsersService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.USERS(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.USERS(), data);
    }

    update(id, data): Observable<any> {
        return this.putRequest(AppUrl.USERS(id), data);
    }

    updateDepartment(id, data): Observable<any> {
        return this.putRequest(AppUrl.USERS_DEPARTMENT(id), data);
    }

    detail(id): Observable<any> {
        return this.getRequest(AppUrl.USERS(id));
    }

    updateStatus(id, data): Observable<any> {
        return this.putRequest(AppUrl.USERS_STATUS(id), data);
    }

    self(): Observable<any> {
        return this.getRequest(AppUrl.SELF);
    }
}
