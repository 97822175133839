<app-breadcrumb [active_item]="'Profile'" [icon]="'fas fa-user-circle-o'" [items]="[]"
                [title]="'Profile'"
                [url]="'/profile'"></app-breadcrumb>
<div class="container-fluid">

    <ng-template #passwordUpdateModal>
        <div id="modal">
            <form [formGroup]="updatePasswordForm">
                <div class="modal-header font-weight-bold">
                    <h4 class="modal-title font-weight-bold">Update Password</h4>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <input type="text" class="form-control" formControlName="currentPassword"
                                   placeholder="Current Password"/>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <input type="text" class="form-control" formControlName="newPassword"
                                   placeholder="New Password"/>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3">
                            <input type="text" class="form-control" formControlName="confirmPassword"
                                   placeholder="Confirm Password"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="updatePassword()"
                            aria-label="Close"
                            class="btn btn-primary color-white pull-right"
                            data-dismiss="modal">
                        <i class="fa fa-lock mr-1"></i> Update Password
                    </button>
                </div>
            </form>
        </div>
    </ng-template>

    <div class="row" *ngIf="user?.user_id!=1">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <div class="row">
                                <div class="col-sm-12 col-md-9"><h5>Profile</h5></div>
                                <div class="col-sm-12 col-md-3 text-center">
                                    <button (click)="openUpdatePasswordModal(passwordUpdateModal)"
                                            class="btn btn-primary btn-block pull-right btn-raised color-white mr-2">
                                        <i class="fa fa-lock mr-1"></i> Update Password
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="card-body shadow-1px-light">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                                    <div class="form-group text-center"
                                         *ngIf="selectedUser && selectedUser?.id_card != null">
                                        <img src="{{selectedUser?.id_card?.profile_image?.url}}"
                                             alt="profile"
                                             style="width: 100px; height: 100px; border-radius: 100%;"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-alt"></i> Name </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fas fa-id-card-alt"></i>
                                            Username </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.username}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-envelope"></i> Email </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.email}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-phone"></i> Contact </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.contact}}"/>

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-birthday-cake"></i> Date Of
                                            Birth</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.dob}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-plus"></i> Date Of
                                            Joining </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.date_of_joining}}"/>

                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            <i class="fa fa-tasks"></i> Role
                                        </label>
                                        <input class="form-control shadow-1px-light  cursor-pointer" disabled readonly
                                               value="{{selectedUser?.user_detail?.role?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <div class="form-group">
                                        <label class="col-form-label">
                                            <i class="fas fa-fax"></i> Department
                                        </label>
                                        <input class="form-control shadow-1px-light  cursor-pointer" disabled readonly
                                               value="{{selectedUser?.departmentNames}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-layer-group"></i>
                                            Designation </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.designation}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-male"></i> Gender</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.gender}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa 	fa fa-group"></i> Marital
                                            Status</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.marital_status}}"/>
                                    </div>
                                </div>


                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-male"></i>Father Name</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.father_name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-address-card"></i> Address </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.address}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-graduation-cap"></i> Highest
                                            Qualification</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.highest_qualification}}"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-home"></i> Emp Number</label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.id_card?.emp_no}}"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="user?.user_id==1">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <div class="row">
                                <div class="col-sm-12 col-md-9"><h5>Profile</h5></div>
                                <div class="col-sm-12 col-md-3 text-center">
                                    <button (click)="openUpdatePasswordModal(passwordUpdateModal)"
                                            class="btn btn-primary btn-block pull-right btn-raised color-white mr-2">
                                        <i class="fa fa-lock mr-1"></i> Update Password
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div class="card-body shadow-1px-light">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                                    <div class="form-group text-center"
                                         *ngIf="selectedUser && selectedUser?.id_card != null">
                                        <img src="{{selectedUser?.id_card?.profile_image?.url}}"
                                             alt="profile"
                                             style="width: 100px; height: 100px; border-radius: 100%;"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-user-alt"></i> Name </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.name}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fas fa-id-card-alt"></i>
                                            Username </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.username}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-envelope"></i> Email </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.email}}"/>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div class="form-group">
                                        <label class="col-form-label"><i class="fa fa-phone"></i> Contact </label>
                                        <input class="form-control shadow-1px-light disabled" disabled readonly
                                               value="{{selectedUser?.contact}}"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row" *ngIf="user?.user_id!=1">
        <div class="col-sm-12 col-xl-12 col-lg-12 col-md-12  box-col-12">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="card card-shadow">
                        <div class="card-header shadow-1px-light p-25px">
                            <h5>Assigned Devices</h5>
                        </div>
                        <div class="card-body shadow-1px-light">
                            <table class="table table-responsive table-bordered">
                                <thead>
                                <tr>
                                    <th>sno.</th>
                                    <th>Name</th>
                                    <th>DeviceId</th>
                                    <th>Brand</th>
                                    <th>Model</th>
                                    <th>Serial Number</th>
                                    <th>Color</th>
                                    <th>Status</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of devices; let i = index;">
                                    <td>{{ i + 1 }}</td>
                                    <td>{{ item?.device?.name }}</td>
                                    <td>{{ item?.device?.device_id }}</td>
                                    <td>{{ item?.device?.brand }}</td>
                                    <td>{{ item?.device?.model }}</td>
                                    <td>{{ item?.device?.serial_number }}</td>
                                    <td>{{ item?.device?.color }}</td>
                                    <td>{{ item?.assign_status == 'ACTIVE' ? 'ASSIGNED' : 'REMOVED' }}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer d-lg-block shadow-1px-light p-25px">
                            <div class="row">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
