import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {AlertService} from './alert.service';
import {UtilsService} from './utils.service';
import {StorageService} from './storage.service';
import {GlobalService} from './global.service';
import {error} from "protractor";

// import {takeUntil} from 'rxjs/internal/operators';

@Injectable()
export class BaseService {
    constructor(public http: HttpClient, public alertService: AlertService, public globalService: GlobalService) {
    }

    public joinMessages(array) {
        let string = '';
        array.forEach(message => {
            string = string + message;
        });
        return string;
    }

    public postRequest(url, data = {}, handler = {success: null, failure: null}, alertHide?) {
        data = UtilsService.deleteEmptyKeys(data);
        return this.postRequestWithoutDataDeletion(url, data, handler, alertHide);
    }

    public postRequestWithoutDataDeletion(url, data = {}, handler = {success: null, failure: null}, alertHide?) {
        return this.http.post(url, data).pipe(takeUntil(this.globalService.isSessionExist), map(res => {
                if (res['message'] && !alertHide) {
                    this.alertService.showSuccess({title: 'Success', message: res['message']});
                }
                return res['data'];
            }), catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public patchRequest(url, data = {}) {
        data = UtilsService.deleteEmptyKeys(data);
        return this.http.patch(url, data).pipe(
            takeUntil(this.globalService.isSessionExist),
            map(res => {
                if (res['message']) {
                    this.alertService.showSuccess({title: 'Success', message: res['message']});
                }
                return res['data'];
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public deleteRequest(url, data = {}) {
        data = UtilsService.deleteEmptyKeys(data);
        return this.http.delete(url, data).pipe(
            takeUntil(this.globalService.isSessionExist),
            map(res => {
                if (res['message']) {
                    this.alertService.showSuccess({title: 'Success', message: res['message']});
                }
                return res['data'];
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public putRequest(url, data = {}) {
        data = UtilsService.deleteEmptyKeys(data);
        return this.putRequestWithoutDataDeletion(url, data);
    }

    public putRequestWithoutDataDeletion(url, data = {}) {
        return this.http.put(url, data).pipe(
            takeUntil(this.globalService.isSessionExist),
            map(res => {
                if (res['message']) {
                    this.alertService.showSuccess({title: 'Success', message: res['message']});
                }
                return res['data'];
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public getRequest(url, params = {}) {
        return this.http.get(url, {params}).pipe(takeUntil(this.globalService.isSessionExist), map(res => {
                return res['data'];
            }),
            catchError(err => {
                return this.handleError(err);
            })
        );
    }

    public getRequestWithoutErrorMessage(url, params = {}) {
        return this.http.get(url, {params}).pipe(takeUntil(this.globalService.isSessionExist), map(res => {
                return res['data'];
            }),
        );
    }


    public handleError(error: HttpErrorResponse) {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && error.error && error.error.type !== 'INVALID_CREDENTIALS') {
                StorageService.clearAll();
                this.globalService.setIsSessionExist(error);
                this.alertService.showErrors('Session Expired!');
            }
        }
        if (error instanceof ErrorEvent) {
        } else {
            /*console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);*/
        }
        switch (error.error.type) {
            case 'INTERNAL_SERVER_ERROR':
            case 'RESOURCE_NOT_FOUND':
            case 'FORBIDDEN_ERROR':
            case 'INVALID_CREDENTIALS':
            case 'BUSINESS_LOGIC_ERROR':
            case 'BAD_REQUEST':
                this.alertService.showErrors(error.error.error);
                return throwError(error.error.error);
            case 'VALIDATION':
                const errorMessageObject = error.error.message;
                this.alertService.showErrors(this.returnArrayOfErrors(errorMessageObject));
                return throwError(this.returnArrayOfErrors(errorMessageObject));
        }
        return throwError(error);
    }

    public returnArrayOfErrors(errorMessageObject) {
        const parsedArray = [];
        Object.keys(errorMessageObject).forEach(message => {
            const keyName = (message.split('.').join(' ')).replace(/([A-Z])/g, ' $1').replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            const description = this.joinMessages(errorMessageObject[message]).split('.').join(' ');
            const errorObject = {
                keyName,
                description,
                completeMessage: `${keyName} : ${description}`
            };
            parsedArray.push(errorObject);
        });
        return parsedArray;
    }

    download_PDF(url, params = {}) {
        return this.http.get(url, {
            params,
            responseType: 'blob' // Ensure the response is treated as a Blob
        }).pipe(
            takeUntil(this.globalService.isSessionExist),
            map((res: Blob) => {
                const blob = new Blob([res], {type: 'application/pdf'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'invoice.pdf';  // You can customize the file name
                link.click();
                window.URL.revokeObjectURL(url);
            }),
            catchError(err => {
                return this.handleError(err);
            }));
    }


    downloadExcel(url: any, params: any) {
        this.http.post(url, params, { responseType: 'blob' }).subscribe((response: Blob) => {
            const fileURL = window.URL.createObjectURL(response); // Create a blob URL
            const a = document.createElement('a');
            a.href = fileURL;
            a.download = `attendance_${params?.date}.xlsx`; // Filename based on params
            document.body.appendChild(a); // Append anchor to the DOM
            a.click(); // Trigger the download
            document.body.removeChild(a); // Remove the anchor after download
            window.URL.revokeObjectURL(fileURL); // Revoke the blob URL after usage
        }, error => {
            console.error('Download error:', error); // Handle error
        });
    }
}
