import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {AlertService} from "./alert.service";
import {GlobalService} from "./global.service";
import {Observable} from "rxjs";
import {AppUrl} from "../constants/app-url";
import {UploadService} from "./upload.service";

@Injectable({
    providedIn: 'root'
})
export class EmployeesService extends BaseService {

    constructor(public http: HttpClient,
                public alertService: AlertService,
                public globalService: GlobalService,
                private fileUploadService: UploadService) {
        super(http, alertService, globalService);
    }

    create(data): Observable<any> {
        return this.postRequest(AppUrl.EMPLOYEES(), data);
    }

    get(data): Observable<any> {
        return this.getRequest(AppUrl.EMPLOYEES(), data);
    }

    getUser(data): Observable<any> {
        return this.getRequest(AppUrl.EMPLOYEE(), data);
    }

    detail(unique_id): Observable<any> {
        return this.getRequest(AppUrl.EMPLOYEES(unique_id));
    }

    update(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.EMPLOYEES(unique_id), data);
    }

    status(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.EMPLOYEE_STATUS(unique_id), data);
    }

    delete(unique_id): Observable<any> {
        return this.deleteRequest(AppUrl.EMPLOYEES(unique_id));
    }

    fileUploadAdmin(params, event): Observable<any> {
        return this.fileUploadService.fileUpload(AppUrl.FILE_UPLOAD(), params, event);
    }

    updateProfileImage(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.POFILE_IMAGE_UPDATE(unique_id), data);
    }

    updateQrCode(unique_id, data): Observable<any> {
        return this.putRequest(AppUrl.QR_CODE_UPDATE(unique_id), data);
    }

}
